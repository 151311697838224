import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { saveAs } from 'file-saver'
import Img from 'gatsby-image'

import { makeStyles } from '@material-ui/core/styles'

import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Button from '@objects/button'
import Card from '@objects/card'

const useStyles = makeStyles((theme) => ({
  singleDownload: {
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 12),
    },
  },
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  content: {
    [theme.breakpoints.up('md')]: {
      order: -1,
    },
  },
  image: {
    width: '100%',
    maxWidth: '400px',
    margin: theme.spacing(0, 'auto', 10, 'auto'),
    [theme.breakpoints.up('md')]: {
      width: '40%',
      maxWidth: 'auto',
      margin: 0,
    },
  },
  copy: {
    ...theme.typography.teaser,
  },
  link: {
    marginTop: theme.spacing(10),
  },
}))

function SingleDownload({ className, headline, copy, file, image }) {
  const classes = useStyles()

  function downloadEvent(src) {
    saveAs(src, src.substring(src.lastIndexOf('/') + 1))
  }

  return (
    <Card className={classes.singleDownload}>
      <div className={classes.inner}>
        {image && (
          <Img
            className={classes.image}
            fluid={{ ...image.fluid, media: `(min-width: 0px)` }}
            alt={image.description}
          />
        )}
        <div className={classes.content}>
          <Headline className={classes.headline} level={4}>
            {headline}
          </Headline>
          <Copy className={classes.copy} html={copy} />

          <Button
            data-track-content
            data-tracking-id={image?.description}
            data-testid="download"
            className={classes.link}
            onClick={() => downloadEvent(file.url)}
            type="text"
            icon="Download"
          >
            <FormattedMessage id="download" /> (ZIP{' '}
            {(file.size / (1024 * 1024)).toFixed(2)} MB)
          </Button>
        </div>
      </div>
    </Card>
  )
}

SingleDownload.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  image: PropTypes.object,
  file: PropTypes.shape({
    url: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }),
}

export default SingleDownload
