import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { FormattedMessage } from 'react-intl'

import useStaticModule from '@hooks/useStaticModule'
import Container from '@objects/container'
import Headline from '@objects/headline'
import Button from '@objects/button'
import MediaPageGallery from '@components/mediaPageGallery'
import { makeStyles } from '@material-ui/core/styles'
import SingleDownload from '@components/singleDownload'

import Icon from '@objects/icon'

export const frontmatter = {
  breadcrumbs: [{ label: 'navigation.media', link: 'media.path' }],
}

const useStyles = makeStyles((theme) => ({
  root: {},
  mainHeadline: {
    marginBottom: theme.spacing(10),
  },
  navigation: {
    marginBottom: theme.spacing(10),
  },
  navItem: {
    lineHeight: 'normal',
    display: 'inline-flex',
    marginRight: theme.spacing(7),

    marginBottom: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(10),
    },
  },
  navIcon: {
    marginLeft: theme.spacing(2),
  },
  headlineLogo: {
    marginBottom: theme.spacing(10),
  },
  singleDownload: {
    margin: 0,
  },
  gallery: {
    marginTop: theme.spacing(15),
    '& ~ &': {
      marginTop: theme.spacing(30),
    },
  },
}))

function PrintmaterialPage({ data, pageContext }) {
  const { getStaticFieldValue, getStaticFieldMedia } = useStaticModule(
    pageContext.modules
  )
  const classes = useStyles()
  const MediaPageList = get(data, 'allContentfulMediaListCategory.nodes')
    .sort((a, b) => a.order - b.order)
    .map((item) => ({
      title: item.title,
      icon: item.icon,
      mediaList: item.mediaLists?.map((media) => ({
        title: media.title,
        id: media.id,
        thumbnail: media.thumbnailWithFocalPoint
          ? media.thumbnailWithFocalPoint.image
          : media.thumbnail,
        focalPoint: media.thumbnailWithFocalPoint?.focalPoint,
        amount: media.files?.length,
        files: media.files,
      })),
    }))

  const SingleDownloadProps = {
    headline: getStaticFieldValue('media.logo', 'media.logo.box.headline'),
    copy: getStaticFieldValue('media.logo', 'media.logo.box.copy'),
    image: getStaticFieldMedia('media.logo', 'media.logo.box.image'),
    file: {
      url: getStaticFieldMedia('media.logo', 'media.logo.box.file')?.file?.url,
      size: getStaticFieldMedia(
        'media.logo',
        'media.logo.box.file'
      )?.file?.details.size.toString(),
    },
  }

  function renderNavItem(id, title, icon) {
    return (
      <Button
        className={classes.navItem}
        key={id}
        type="primary"
        to={`#${id}`}
        useScrollOffset={false}
        dataTracking="true"
        dataTrackingCategory="Button"
        dataTrackingAction="Klick"
        dataTrackingName={icon}
      >
        {title}
        <Icon
          className={classes.navIcon}
          name={icon ?? 'Logo'}
          size="inherit"
        />
      </Button>
    )
  }

  function renderNav() {
    const mediaTags = MediaPageList.map((list) =>
      renderNavItem(
        list.title.replace(/\s/g, '-').toLowerCase(),
        list.title,
        list.icon
      )
    )

    return [renderNavItem('logo', 'Logo', 'Logo'), ...mediaTags]
  }

  function renderGalleries() {
    return MediaPageList.map((list) => (
      <MediaPageGallery
        key={list.title}
        className={classes.gallery}
        title={list.title}
        mediaList={list.mediaList}
      />
    ))
  }

  return (
    <>
      <Container type={'top'}
      ariaLabel="arialabel.printmaterial"
      >
        <Headline level={1} className={classes.mainHeadline}>
          <FormattedMessage id="printmaterial.headline" />
        </Headline>
        <div className={classes.navigation}>{renderNav()}</div>
        <Headline id={'logo'} className={classes.headlineLogo} level={3}>
          {getStaticFieldValue('media.logo', 'media.logo.headline')}
        </Headline>
        <SingleDownload
          className={classes.singleDownload}
          {...SingleDownloadProps}
        />
        {renderGalleries()}
      </Container>
    </>
  )
}

PrintmaterialPage.propTypes = {
  data: PropTypes.any,
  modules: PropTypes.array,
}

export default PrintmaterialPage

export const pageQuery = graphql`
  query PrintmaterialPageQuery($locale: String!) {
    allContentfulMediaListCategory(
      filter: {
        node_locale: { eq: $locale }
        title: { in: ["Printmaterialien", "PDF Downloads"] }
      }
    ) {
      nodes {
        title
        icon
        identifier
        order
        mediaLists {
          id
          title
          thumbnail {
            fluid(quality: 80, maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
          thumbnailWithFocalPoint {
            image {
              fluid(quality: 80, maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            focalPoint
          }
          files {
            __typename

            ... on ContentfulPrintMaterial {
              id
              title
              trackingTitle
              createdAt
              subtitle
              copy {
                copy
              }
              images {
                id
                title
                description
                fluid(maxWidth: 2000, quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              variants {
                id
                variantName
                variantFile {
                  file {
                    details {
                      size
                    }
                    contentType
                    url
                  }
                }
              }
              isOnlyDownload
            }
          }
        }
      }
    }
  }
`
